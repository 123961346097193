<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t('blog') }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white;padding-top: 120px;padding-bottom: 120px;"
    >
      <div class="container">
        <div class="blogPost" v-for="item in list" :key="item.id">
          <img
            :src="$baseUploadURL2 + item.image"
            alt="Image Blog"
            class="img-responsive"
          />
          <h2>
            <router-link :to="$getLink() + `blog-single/`+item.id">{{ item.name }}</router-link>
          </h2>
          <p>
            {{ item.body }}
          </p>
          <ul class="list-inline">
            <li>
              <a>
                <i class="fa fa-user" aria-hidden="true"></i>
                {{ item.created_at }}</a
              >
            </li>
            <!-- <li>
              <a href="blog-single-fullwidth.html"
                ><i class="fa fa-heart" aria-hidden="true"></i> 21 Likes</a
              >
            </li> -->
            <li>
              <router-link :to="$getLink() + `blog-single/`+item.id"
                ><i class="fa fa-comments-o" aria-hidden="true"></i>
                {{ item.blogcomment.length }} {{ $t('Comments') }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },

  created() {
    this.$http2.get('blog').then(
      (res) => {
        this.list = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  },
};
</script>

<style>
</style>
